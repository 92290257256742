/* FRONT PAGE SCRIPTS */

jQuery(document).ready(function($) {	
	initPreloaderScreen();
	initSliders();	
	initHellobar();	
});

function initPreloaderScreen() {
	setTimeout(function(){
		jQuery('.home #loader').addClass('animated zoomOut');		
	}, 1000);
	setTimeout(function(){		
		jQuery('.home #loader-wrapper').fadeOut('slow');	
		jQuery('.home .off-canvas-wrapper').css('opacity', 1);
	}, 1550);
	initSubmenus();
}


function initSubmenus() {
	jQuery('.top-bar #menu-topnav-1').css('opacity',1);	
/*	setTimeout(function(){
		jQuery('.top-bar .fixedlogo').css('display','block').addClass('animated fadeInLeft');
	}, 800); */
}


function initSliders(){	
	/* https://swiperjs.com/get-started/  */

	var fpcarouselinner = jQuery('.fp-carousel-row-inner');
	if(fpcarouselinner.length) {  
	  var mySwiper = new Swiper ('.fp-carousel-row-inner' , {
	    // Optional parameters
	    direction: 'horizontal',
	    loop: true,
	    autoHeight: true,
	    slidesPerView: 1,
		spaceBetween: 10,
	
	    // If we need pagination
	    pagination: {
	      el: '.swiper-pagination',
	      clickable: true,
	      type: 'bullets',
	      	      
	    },
	
	    // Navigation arrows
	    navigation: {
	      nextEl: '.swiper-button-next',
	      prevEl: '.swiper-button-prev',
	    },
	
	    // And if we need scrollbar
	    scrollbar: {
	      el: '.swiper-scrollbar',
	      draggable: true,
	    },
	  })
	}
}	

function initHellobar(){
	var helloBarContainer = jQuery('#hello-bar-main');
	if(helloBarContainer.length){
		jQuery('#hello-bar-close-btn').click(function() {
		 helloBarContainer.animate({
			  maxHeight: '0',
			  opacity: '0',
			  padding: '0',
			});
		});	
	}
}

// VIDEO FOR FRONT PAGE
function initHpVideo() {		
			var isTouchDevice = /Windows Phone/.test(navigator.userAgent) || ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch;
		
	jQuery('.banner-wrapper .video').each(function() {
		var holder = jQuery(this);
		var video = holder.find('video');
		var isDesktop = jQuery(document).width() < 1025 ? true : false; 
		
		if (isTouchDevice && isDesktop) {
			video.remove();
			jQuery(".banner-wrapper .video").addClass("mobile");
			jQuery(".banner-wrapper .video").removeClass("desktop");
		} else {
			jQuery(".banner-wrapper .video").addClass("desktop");
		}
	});
}